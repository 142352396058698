import React from "react";
import axios from 'axios';

import "./Holders.css";

const HoldersList = ({setHolderSearchResults, holderSearchResults, setHolderInput, setHolder, setRequirements, qualificationVersionUuid, setValid, setRequirementSets, setAdminComments, isAdmin, isSuperUser}) => {

    const handleHolderSelect = async (result) => {
        setHolderInput(result.surname + ", " + result.givenName + " (" + result.email + ")");
        setHolderSearchResults([]);
        setHolder(result);
        try
        {
            let results = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/version/holder/" + result.holderUuid, 
                { 
                    params: {
                        qualificationVersionUuid: qualificationVersionUuid
                    },
                    withCredentials: true 
                });
            let sortedRequirements = results.data.requirements.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            let requirementSetResult = await axios.get(process.env.REACT_APP_BASE_API_URL + 
                "/qualification/version/" + qualificationVersionUuid + "/requirementSets", 
                { withCredentials: true } );
            let sortedRequirementSets = requirementSetResult.data.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            const requirementSetsArray = [];
            sortedRequirementSets.forEach((value) => {
                requirementSetsArray.push({
                    uuid: value.uuid,
                    name: value.name,
                    description: value.description,
                    requirements: sortedRequirements.filter((r) =>
                        r.requirementSet &&
                        r.requirementSet.requirementSetUuid === value.uuid
                    )
                });
            });
            let filteredRequirements = sortedRequirements.filter((r) => !r.requirementSet);
            setRequirements(filteredRequirements);
            setRequirementSets(requirementSetsArray);
            setValid(results.data.valid);
            if (isAdmin || isSuperUser)
            {
                let response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/qualificationHolder/comments/" +
                    result.holderUuid, { withCredentials: true });
                setAdminComments(response.data)
            }
            
        }
        catch (error)
        {
            console.log(error);
        }
    }

    if (holderSearchResults.length > 0)
    {
        return (
            <div className="results-list">
                {holderSearchResults.map((result) => {
                    return <div className="search-result" key={result.email} onClick={() => handleHolderSelect(result)}>
                                {result.surname}, {result.givenName} ({result.email})
                            </div>
                })}
            </div>
        );
    }
};

export default HoldersList;
