import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import './RequirementStyle.css'
import { Link } from 'react-router-dom';

const RequirementSet = ({setAlertVariant, setMessage, qualification, update, setUpdate}) => {

    const [createButtonClicked, setCreateButtonClicked] = useState(false);
    const [addButtonClicked, setAddButtonClicked] = useState(false);
    const [requirementSets, setRequirementSets] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [requirementSetOptions, setRequirementSetOptions] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [requirementQualificationUuid, setRequirementQualificationUuid] = useState("");
    const [requirementSetUuid, setRequirementSetUuid] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try
            {
                if (qualification.uuid)
                {
                    let requirementSetResult = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/" + qualification.uuid + "/requirementSets", 
                        { withCredentials: true } );
                    let sortedRequirementSets = requirementSetResult.data.sort(function (a, b) {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					});
                    setRequirementSets(sortedRequirementSets);
                    const requirementSetOptionsArray = [];
                    sortedRequirementSets.forEach((value) => {
                        let key = value.name;
                        if (value.description)
                        {
                            key = key + " - " + value.description;
                        }
                        requirementSetOptionsArray.push({
                            key: key,
                            value: value.uuid,
                        });
                    });
                    setRequirementSetOptions([{key: 'Select Requirement Set', value: ''}, ...requirementSetOptionsArray]);
                    let requirementsResult = await axios.get(process.env.REACT_APP_BASE_API_URL + '/qualification/' + qualification.uuid, 
                        { withCredentials: true, params: { includeRequirements: true } } );
                    const requirementsArray = [];
                    requirementsResult.data.requirements.forEach((value) => {
                        let key = value.name;
                        if (value.description)
                        {
                            key = key + " - " + value.description;
                        }
                        requirementsArray.push({
                            key: key,
                            value: value.requirementQualificationUuid,
                        });
                    });
                    setRequirements([{key: 'Select Requirement', value: ''}, ...requirementsArray]);
                    
                }
            }
            catch (error)
            {
                console.log(error);
            }
        };
		fetchData();
	}, [qualification.uuid, update]);

    let handleCreate = async (e) => {
        e.preventDefault();
        try
        {
            setCreateButtonClicked(true);
            let requirementSet = {
                name: name,
                description: description
            };
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/qualification/requirementSet/create/" + qualification.uuid, 
                requirementSet, { withCredentials: true});
            setName("");
            setDescription("");
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            setCreateButtonClicked(false);
            window.scrollTo(0, 0);
        }
    };

    let handleDelete = async (e, requirementSetUuid) => {
        e.preventDefault();
        console.log(requirementSetUuid)
        try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/qualification/requirementSet/delete/" + requirementSetUuid, 
                { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
        }
    };

    let handleAdd = async (e) => {
        e.preventDefault();
        try
        {
            setAddButtonClicked(true);
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + 
                    "/qualification/requirementSet/" + requirementSetUuid + "/add/" + requirementQualificationUuid, 
                {}, { withCredentials: true});
            setRequirementQualificationUuid("");
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            setAddButtonClicked(false);
            window.scrollTo(0, 0);
        }
    };

	return (
        <>
            <div className="d-flex align-items-center justify-content-center p-3">
                <div className="Outer">
                    <Form onSubmit={handleCreate} className='Form'>
                        <div className="Center-Text Margin-Bottom"><h5>Requirement Sets</h5></div>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Name *</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name"
                                value={name}
                                name="name" 
                                onChange={(e) => setName(e.target.value)} 
                                required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" placeholder="Enter Description"
                                value={description}
                                name="description" 
                                onChange={(e) => setDescription(e.target.value)} />
                        </Form.Group>
                        <p>
                            { '* Required' }
                        </p>
                        <Button variant="primary" type="submit" hidden={createButtonClicked} className="Margin-Bottom">
                            Create Requirement Set
                        </Button>
                        <Button variant="primary" disabled hidden={!createButtonClicked} className="Margin-Bottom">
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                        <div>
                            {requirementSets.map((rs) => {
                                return (
                                    <div key={rs.uuid}>
                                        {rs.name} 
                                        {rs.description && 
                                            <>
                                                {' '}- {rs.description}
                                            </>
                                        }
                                        <Link onClick={(e) => handleDelete(e, rs.uuid)}>
                                            {' '}[Remove]
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </Form>
                    
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-center p-3">
                <div className="Outer">
                    <Form onSubmit={handleAdd} className='Form'>
                        <div className="Center-Text Margin-Bottom"><h5>Requirement Set Membership</h5></div>
                        <Form.Control as="select"
                            value={requirementQualificationUuid}
                            name="requirement" 
                            onChange={(e) => setRequirementQualificationUuid(e.target.value)} 
                            className="drop-down-list Margin-Bottom"
                            required>
                            {requirements.map((option) => {
                                return (
                                    <option key={option.value} value={option.value}>
                                        {option.key}
                                    </option>
                                );
                            })}
                        </Form.Control>
                        <Form.Control as="select"
                            value={requirementSetUuid}
                            name="requirement" 
                            onChange={(e) => setRequirementSetUuid(e.target.value)} 
                            className="drop-down-list Margin-Bottom"
                            required>
                            {requirementSetOptions.map((option) => {
                                return (
                                    <option key={option.value} value={option.value}>
                                        {option.key}
                                    </option>
                                );
                            })}
                        </Form.Control>
                        <Button variant="primary" type="submit" hidden={addButtonClicked} className="Margin-Bottom">
                            Add To Requirement Set
                        </Button>
                        <Button variant="primary" disabled hidden={!addButtonClicked} className="Margin-Bottom">
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    </Form>
                </div>
            </div>
        </>
	);
};

export default RequirementSet;
