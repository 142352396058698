import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

const QualificationVersion = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState({});
    const [qualification, setQualification] = useState({});
    const [qualificationVersion, setQualificationVersion] = useState({});
    const [dateButtonClicked, setDateButtonClicked] = useState(false);
    const [deprecationDate, setDeprecationDate] = useState();
    const [update, setUpdate] = useState(false);
    const [requirements, setRequirements] = useState([]);
    const [requirementSets, setRequirementSets] = useState([]);
    
    const organizationUuid = useRef();
    const qualificationVersionUuid = useRef();

    useEffect(() => {
        fetchData();
        setUpdate(false);
    }, [update]);

    const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
		    organizationUuid.current = queryParameters.get("organizationUuid");
            qualificationVersionUuid.current = queryParameters.get("qualificationVersionUuid");
            let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid.current, 
                { withCredentials: true });
            setOrganization(organizationResponse.data);
            let requirementSetResult = await axios.get(process.env.REACT_APP_BASE_API_URL + 
                "/qualification/version/" + qualificationVersionUuid.current + "/requirementSets", 
                { withCredentials: true } );
            let sortedRequirementSets = requirementSetResult.data.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            
            let qualificationVersionResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/version/" + qualificationVersionUuid.current, 
                { withCredentials: true });
            let sortedRequirements = qualificationVersionResponse.data.requirements.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            setQualificationVersion(qualificationVersionResponse.data);
            setDeprecationDate(qualificationVersionResponse.data.deprecationDate.split('T')[0]);
            let qualificationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/" + qualificationVersionResponse.data.qualificationUuid, 
                { withCredentials: true });
            setQualification(qualificationResponse.data);

            const requirementSetsArray = [];
            sortedRequirementSets.forEach((value) => {
                requirementSetsArray.push({
                    uuid: value.uuid,
                    name: value.name,
                    description: value.description,
                    requirements: sortedRequirements.filter((r) =>
                        r.requirementSet &&
                        r.requirementSet.requirementSetUuid === value.uuid
                    )
                });
            });

            let filteredRequirements = sortedRequirements.filter((r) => !r.requirementSet);
            setRequirements(filteredRequirements);
            setRequirementSets(requirementSetsArray);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    const formatDate = (dateInputString) => {
        if (!dateInputString)
        {
            return;
        }
        const date = Date.parse(dateInputString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC'
        };
        return Intl.DateTimeFormat(undefined, options).format(date);
	}

    let handleUpdateDeprecationDate = async (e) => {
        e.preventDefault();
        setDateButtonClicked(true);
        const version = {
            deprecationDate: deprecationDate
        };

        try 
        {
            
            let response = await axios.put(process.env.REACT_APP_BASE_API_URL + '/qualification/version/update/' + qualificationVersionUuid.current, 
                version, { withCredentials: true } );  
            setMessage(response.data.message);
            setAlertVariant("primary");  
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setDateButtonClicked(false);
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Qualification Version</title>
            </Helmet>
            <div className="p-2">
                <h6>
                    <a href={"/administer?organizationUuid=" + organization.uuid }>{organization.name}</a>{' > '} 
                    <a href={"/qualification/list?organizationUuid=" + organization.uuid }>Qualifications</a> {' > '} 
                    <a href={"/qualification/edit?organizationUuid=" + organization.uuid + "&qualificationUuid=" + qualification.uuid}>{qualification.name}</a> {' > '} 
                    {qualificationVersion.versionName}
                </h6>
            </div>
            <div className="p-3 Center-Outer">
                <div className="Outer">
                    <h3 className="Center-Text Margin-Bottom">Qualification Version</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <div className="Details Margin-Bottom">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Qualification: </td>
                                    <td>{qualificationVersion.qualificationName}</td>
                                </tr>
                                <tr>
                                    <td>Version: </td>
                                    <td>{qualificationVersion.versionName}</td>
                                </tr>
                                {qualificationVersion.description &&
                                    <tr>
                                        <td>Description: </td>
                                        <td>{qualificationVersion.description}</td>
                                    </tr>
                                }
                                <tr>
                                    <td>Type: </td>
                                    <td>{qualificationVersion.type}</td>
                                </tr>
                                <tr>
                                    <td>Expiration Type: </td>
                                    <td>
                                        { qualificationVersion.qualificationExpirationType &&
                                            <>
                                                {qualificationVersion.qualificationExpirationType.name} - {qualificationVersion.qualificationExpirationType.description}
                                            </>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Evaluator Requirement: </td>
                                    <td>
                                        { qualificationVersion.evaluatorRequirement &&
                                            <>
                                                {qualificationVersion.evaluatorRequirement.name}
                                            </>
                                        }
                                    </td>
                                </tr>
                                { qualificationVersion.expiryDaysFromCompletion > 0 &&
                                    <tr>
                                        <td>Expiry Days After Completion: </td>
                                        <td>{qualificationVersion.expiryDaysFromCompletion}</td>
                                    </tr>
                                }
                                { qualificationVersion.expiryDate !== "0001-01-01T00:00:00" &&
                                    <tr>
                                        <td>Expiry Date: </td>
                                        <td>{formatDate(qualificationVersion.expiryDate)}</td>
                                    </tr>
                                }
                                <tr>
                                    <td>Published By: </td>
                                    <td>{qualificationVersion.publishedBySurname}, {qualificationVersion.publishedByGivenName}</td>
                                </tr>
                                <tr>
                                    <td>Deprecation Date: </td>
                                    <td>{formatDate(qualificationVersion.deprecationDate)}</td>
                                </tr>
                                <tr>
                                    <td>NAICS Classification: </td>
                                    <td>
                                        { qualificationVersion.naicsCode &&
                                            <>
                                                {qualificationVersion.naicsCode.definition}
                                            </>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="Form Margin-Bottom">
                        <h5>Deprecation Date</h5>
                        <div>Must be today or later.</div>
                        <Form onSubmit={handleUpdateDeprecationDate}>
                        <Form.Group className="mb-3" controlId="formDate">
                            <Form.Control type="date" 
                                defaultValue={deprecationDate}
                                name="date" 
                                onChange={(e) => setDeprecationDate(e.target.value)} 
                                required />
                            </Form.Group>
                            <Button variant="primary" type="submit" hidden={dateButtonClicked}>
                                Update Deprecation Date
                            </Button>
                            <Button variant="primary" disabled hidden={!dateButtonClicked}>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Loading...
                            </Button>
                        </Form>
                    </div>
                    <h5 className="Margin-Bottom">Requirements</h5>
                    { requirementSets.map((requirementSet) => {
                        let iteration = 0;
                        return(
                            <div className="Details Margin-Bottom" key={requirementSet.uuid}>
                                <h5 className="mb-1">{requirementSet.name}</h5>
                                <div>
                                    {requirementSet.description}
                                </div>
                                { requirementSet.requirements && requirementSet.requirements.map((requirement) => {
                                    iteration = iteration + 1;
                                    return(
                                        <div key={requirement.requirementVersionQualificationVersionUuid}>
                                            <>
                                                { iteration > 1 &&
                                                    <div>OR</div>
                                                }
                                            </>
                                            <div className="list-group">
                                                <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">{requirement.name}</h5>
                                                        <small>
                                                            {requirement.requirementType.name}
                                                        </small>
                                                    </div>
                                                    {requirement.description &&
                                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                                            <p className="mb-1">{requirement.description}</p>
                                                        </div>
                                                    }
                                                    { requirement.expirationDays > 0 &&
                                                        <div>
                                                            <small>Expiration Days: {requirement.expirationDays}</small>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                    { requirements && requirements.map((requirement) => {
                            return(
                                <div className="list-group" key={requirement.requirementVersionQualificationVersionUuid}>
                                    <div className="list-group-item list-group-item-action flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{requirement.name}</h5>
                                            <small>
                                                {requirement.requirementType.name}
                                            </small>
                                        </div>
                                        { requirement.description &&
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                <p className="mb-1">{requirement.description}</p>
                                            </div>
                                        }
                                        { requirement.expirationDays > 0 &&
                                            <div>
                                                <small>Expiration Days: {requirement.expirationDays}</small>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                    })}
                </div> 
            </div> 
        </HelmetProvider>
	);
};

export default QualificationVersion;
