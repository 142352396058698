import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ReactCountryDropdown from "react-country-dropdown";
import axios from 'axios';
import TelephoneNumber from "../../TelephoneNumber";
import SelectLanguage from "./SelectLanguage";
import {v4 as uuidv4} from 'uuid';
import OperationalJurisdictions from "./OperationalJurisdictions";

const FooterLinks = () => {
    return (
        <>
            <div className="d-flex flex-wrap">
                <div className="flex-grow-1 text-center">
                    <a href="/login" className="link-primary">Login</a>
                </div>
                <div className="flex-grow-1 text-center">
                    {"\u00A0|\u00A0"}
                </div>
                <div className="flex-grow-1 text-center">
                    <a href="/passwordreset" className="link-primary">Forgot Password</a>
                </div>
                <div className="flex-grow-1 text-center">
                    {"\u00A0|\u00A0"}
                </div>
                <div className="flex-grow-1 text-center">
                    <a href="/register/resend" className="link-primary">Resend Registration Email</a>
                </div>
            </div>
        </>
    );
}

const Register = () => {

    const [username, setUsername] = useState("");
    const [givenName, setGivenName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [surname, setSurname] = useState("");
    const [gender, setGender] = useState("");
    const [ethnicity, setEthnicity] = useState("");
    const [education, setEducation] = useState("");
    const [preferredLanguage, setPreferredLanguage] = useState("");
    const [additionalLanguage, setAdditionalLanguage] = useState("");
    const [additionalLanguageProficiency, setAdditionalLanguageProficiency] = useState("");
    const [additionalLanguages, setAdditionalLanguages] = useState([]);
    const [operationalJurisdictions, setOperationalJurisdictions] = useState([]);
    const [telephoneNumber, setTelephoneNumber] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [organization, setOrganization] = useState("");
    const [title, setTitle] = useState("");
    const [industryExperience, setIndustryExperience] = useState("");
    const [city, setCity] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [employmentType, setEmploymentType] = useState("");
    const [naicsOptions, setNaicsOptions] = useState([]);
    const [naicsCodeId, setNaicsCodeId] = useState("");
    const [stateProvince, setStateProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("US");
    const [citizenship, setCitizenship] = useState("US");
    const [professionalOrganizations, setProfessionalOrganizations] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [message, setMessage] = useState("");
    const [additionalLanguageMessage, setAdditionalLanguageMessage] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);
    const [alertVariant, setAlertVariant] = useState("danger");
    const [displayForm, setDisplayForm] = useState(true);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        let fetchData = async () => {
            if (naicsOptions.length < 1)
            {
                let result = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + '/naicsCodes/');
                const industryArray = [];
                result.data.forEach((value) => {
                    industryArray.push({
                        key: value.definition,
                        value: value.id,
                    });
                });
                setNaicsOptions([{key: 'Select Industry', value: ''}, ...industryArray]);
            }
        };
		fetchData();
        setUpdate(false);
        // eslint-disable-next-line
	}, [update]);

    function additionalLanguageExists(language) {
        let match = additionalLanguages.filter((l) =>
            l.language === language.language  
        );
        let exists = false;
        if (match.length > 0)
        {
            exists = true;
        }
        return exists;
    }

    let handleSubmit = async (e) => {
        e.preventDefault();
        if (password1 !== password2)
        {
            setMessage("The passwords do not match.");
            return;
        }

        setButtonClicked(true);

        const userInfo = {
            username: username,
            password: password1,
            givenName: givenName,
            surname: surname,
            organization: organization,
            telephoneNumber: telephoneNumber,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateProvince: stateProvince,
            postalCode: postalCode,
            country: country,
            title: title,
            employmentType: employmentType,
            naicsCodeId: naicsCodeId,
            dateOfBirth: dateOfBirth,
            gender: gender,
            citizenship: citizenship,
            education: education,
            linkedInProfile: linkedIn,
            preferredLanguage: preferredLanguage,
            additionalLanguages: additionalLanguages,
            ethnicity: ethnicity,
            professionalOrganizations: professionalOrganizations,
            industryExperience: industryExperience,
            operationalJurisdictions: operationalJurisdictions
        };

        try 
        {
            let response = await axios.post(process.env.REACT_APP_BASE_AUTH_API_URL + '/register', userInfo );
            setDisplayForm(false);
            setAlertVariant("primary");               
            setMessage(response.data.message);
        }
        catch (error)
        {
            console.log(error)
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setButtonClicked(false);
        }
    };

    let handleAddAdditionalLanguage = async (e) => {
        e.preventDefault();
        if (!additionalLanguage)
        {
            setAdditionalLanguageMessage("Please select a language.");
            return;
        }
        if (!additionalLanguageProficiency)
        {
            setAdditionalLanguageMessage("Please select a proficiency.");
            return;
        }
        let newLanguage = {
            language: additionalLanguage,
            proficiency: additionalLanguageProficiency
        };
        if (additionalLanguageExists(newLanguage))
        {
            return;
        }
        setAdditionalLanguages(additionalLanguages => [...additionalLanguages, newLanguage]);
        setAdditionalLanguageMessage("");
        setUpdate(true);
	}

    let handleRemoveAdditionalLanguage = async (e, language) => {
        e.preventDefault();
		const index = additionalLanguages.indexOf(language);
        if (index > -1) {
            additionalLanguages.splice(index, 1);
            setUpdate(true);
        }
	}

    if (displayForm) {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Register</title>
                </Helmet>
                <div className="d-flex align-items-center justify-content-center p-3">
                    <div className="Outer">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="d-flex align-items-center justify-content-center">
                                        <h3>Register</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="d-flex align-items-center justify-content-center Outer">    
                                        {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="d-flex align-items-center justify-content-center">
                                                <Form onSubmit={handleSubmit} className='Form'>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Username (email address) *</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter Username"
                                                        value={username}
                                                        name="username" 
                                                        onChange={(e) => setUsername(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formGivenName">
                                                        <Form.Label>First Name *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter First Name"
                                                        value={givenName}
                                                        name="givenName" 
                                                        onChange={(e) => setGivenName(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formSurname">
                                                        <Form.Label>Last Name *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Last Name"
                                                        value={surname}
                                                        name="surname" 
                                                        onChange={(e) => setSurname(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formDate">
                                                        <Form.Label>Date of Birth *</Form.Label>
                                                        <Form.Control type="date" 
                                                            defaultValue={dateOfBirth}
                                                            name="date" 
                                                            onChange={(e) => setDateOfBirth(e.target.value)} 
                                                            required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formGender">
                                                        <Form.Label>Gender *</Form.Label>
                                                        <Form.Control as="select"
                                                            value={gender}
                                                            name="gender" 
                                                            onChange={(e) => setGender(e.target.value)} 
                                                            required>
                                                                <option value="">
                                                                    Select Gender
                                                                </option>
                                                                <option value="F">
                                                                    Female
                                                                </option>
                                                                <option value="M">
                                                                    Male
                                                                </option>
                                                                <option value="O">
                                                                    Other
                                                                </option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formEthnicity">
                                                        <Form.Label>Ethnicity *</Form.Label>
                                                        <Form.Control as="select"
                                                            value={ethnicity}
                                                            name="ethnicity" 
                                                            onChange={(e) => setEthnicity(e.target.value)} 
                                                            required >
                                                                <option value="">
                                                                    Select Ethnicity
                                                                </option>
                                                                <option value="AIAN">
                                                                    American Indian or Alaska Native
                                                                </option>
                                                                <option value="ASIAN">
                                                                    Asian
                                                                </option>
                                                                <option value="BAA">
                                                                    Black or African American
                                                                </option>
                                                                <option value="HISPANIC">
                                                                    Hispanic
                                                                </option>
                                                                <option value="NHPI">
                                                                    Native Hawaiian or Other Pacific Islander
                                                                </option>
                                                                <option value="WC">
                                                                    White or Caucasian
                                                                </option>
                                                                <option value="MEO">
                                                                    Multiple Ethnicity / Other
                                                                </option>
                                                                <option value="NONE">
                                                                    I Choose Not to Answer
                                                                </option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formCitizenship">
                                                        <Form.Label>Citizenship *</Form.Label><br />
                                                        <ReactCountryDropdown
                                                            defaultCountry="US"
                                                            onSelect={(e) => setCitizenship(e.code)}
                                                            required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formEducation">
                                                        <Form.Label>Highest Education Achieved *</Form.Label>
                                                        <Form.Control as="select"
                                                            value={education}
                                                            name="education" 
                                                            onChange={(e) => setEducation(e.target.value)} 
                                                            required>
                                                            <option value="">
                                                                Select Education
                                                            </option>
                                                            <option value="AD">
                                                                Associate's Degree
                                                            </option>
                                                            <option value="BD">
                                                                Bachelor's Degree
                                                            </option>
                                                            <option value="DD">
                                                                Doctoral Degree
                                                            </option>
                                                            <option value="ES">
                                                                Elementary School
                                                            </option>
                                                            <option value="HS">
                                                                High School Diploma or Equivalent
                                                            </option>
                                                            <option value="MS">
                                                                Middle School
                                                            </option>
                                                            <option value="SC">
                                                                Some College
                                                            </option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formOrganization">
                                                        <Form.Label>Organization</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Organization"
                                                        value={organization}
                                                        name="organization" 
                                                        onChange={(e) => setOrganization(e.target.value)} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formTitle">
                                                        <Form.Label>Title</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Title"
                                                        value={title}
                                                        name="title" 
                                                        onChange={(e) => setTitle(e.target.value)} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formEmploymentType">
                                                        <Form.Label>Employment Type</Form.Label>
                                                        <Form.Control as="select"
                                                            value={employmentType}
                                                            name="employmentType" 
                                                            onChange={(e) => setEmploymentType(e.target.value)} >
                                                                <option value="">
                                                                    Select Employment Type
                                                                </option>
                                                                <option value="CONS">
                                                                    Consultant
                                                                </option>
                                                                <option value="CONT">
                                                                    Contractor
                                                                </option>
                                                                <option value="FT">
                                                                    Full-Time
                                                                </option>
                                                                <option value="PT">
                                                                    Part-Time
                                                                </option>
                                                                <option value="PD">
                                                                    Per diem
                                                                </option>
                                                                <option value="V">
                                                                    Volunteer
                                                                </option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formNaics">
                                                        <Form.Label>Industry *</Form.Label>
                                                        <Form.Control as="select"
                                                            value={naicsCodeId}
                                                            name="naics" 
                                                            onChange={(e) => setNaicsCodeId(e.target.value)}
                                                            required >
                                                            {naicsOptions.map((option) => {
                                                                return (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.key}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formDateOfHire">
                                                        <Form.Label>Industry Experience Beginning *</Form.Label>
                                                        <Form.Control type="date" 
                                                            defaultValue={industryExperience}
                                                            name="date" 
                                                            onChange={(e) => setIndustryExperience(e.target.value)} 
                                                            required />
                                                    </Form.Group>
                                                    <OperationalJurisdictions 
                                                        operationalJurisdictions = {operationalJurisdictions}
                                                        setOperationalJurisdictions = {setOperationalJurisdictions}
                                                        setUpdate={setUpdate}
                                                    />
                                                    <Form.Group className="mb-3" controlId="formAddressLine1">
                                                        <Form.Label>Address Line 1 *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Address Line 1"
                                                        value={addressLine1}
                                                        name="addressLine1" 
                                                        onChange={(e) => setAddressLine1(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formAddressLine2">
                                                        <Form.Label>Address Line 2</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Address Line 2"
                                                        value={addressLine2}
                                                        name="addressLine2" 
                                                        onChange={(e) => setAddressLine2(e.target.value)} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formCity">
                                                        <Form.Label>City *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter City"
                                                        value={city}
                                                        name="city" 
                                                        onChange={(e) => setCity(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formStateProvince">
                                                        <Form.Label>State/Province *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter State/Province"
                                                        value={stateProvince}
                                                        name="stateProvince" 
                                                        onChange={(e) => setStateProvince(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formPostalCode">
                                                        <Form.Label>Postal Code *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Postal Code"
                                                        value={postalCode}
                                                        name="postalCode" 
                                                        onChange={(e) => setPostalCode(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formCountry">
                                                        <Form.Label>Country *</Form.Label><br />
                                                        <ReactCountryDropdown
                                                        defaultCountry="US"
                                                        onSelect={(e) => setCountry(e.code)}
                                                        required />
                                                    </Form.Group>
                                                    <TelephoneNumber telephoneNumber={telephoneNumber} setTelephoneNumber={setTelephoneNumber} />
                                                    <Form.Group className="mb-3" controlId="formLinkedIn">
                                                        <Form.Label>LinkedIn Profile</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter LinkedIn Profile"
                                                        value={linkedIn}
                                                        name="linkedIn" 
                                                        onChange={(e) => setLinkedIn(e.target.value)} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formLanguage">
                                                        <Form.Label>Preferred Language *</Form.Label>
                                                        <SelectLanguage 
                                                            setLanguage={setPreferredLanguage}
                                                            language={preferredLanguage}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formLanguage">
                                                        <Form.Label>Additional Languages</Form.Label>
                                                        {additionalLanguageMessage ? <Alert key="messageAlert" variant="danger">{additionalLanguageMessage}</Alert> : null}
                                                        <SelectLanguage 
                                                            setLanguage={setAdditionalLanguage}
                                                            language={additionalLanguage}
                                                        />
                                                        {additionalLanguage &&
                                                            <>
                                                                <Form.Control as="select"
                                                                    value={additionalLanguageProficiency}
                                                                    name="additionalLanguageProficiency" 
                                                                    onChange={(e) => setAdditionalLanguageProficiency(e.target.value)}
                                                                    style={{marginTop: "15px"}}>
                                                                        <option value="">
                                                                            Select Proficiency
                                                                        </option>
                                                                        <option value="B">
                                                                            Beginner
                                                                        </option>
                                                                        <option value="I">
                                                                            Intermediate
                                                                        </option>
                                                                        <option value="A">
                                                                            Advanced
                                                                        </option>
                                                                        <option value="F">
                                                                            Fluent
                                                                        </option>
                                                                </Form.Control>
                                                                <Button variant="primary" onClick={(e) => handleAddAdditionalLanguage(e)} 
                                                                    type="submit" style={{ marginTop: "15px"}} className="Margin-Bottom">
                                                                    Add
                                                                </Button>
                                                                {additionalLanguages.map((language) => {
                                                                    let uuid = uuidv4();
                                                                    let proficiency = "";
                                                                    switch (language.proficiency) {
                                                                        case "B":
                                                                            proficiency = "Beginner";
                                                                            break;
                                                                        case "I":
                                                                            proficiency = "Intermediate";
                                                                            break;
                                                                        case "A":
                                                                            proficiency = "Advanced";
                                                                            break;
                                                                        case "F":
                                                                            proficiency = "Fluent";
                                                                            break;
                                                                        default:
                                                                            proficiency = "Unknown";
                                                                            break;
                                                                    }
                                                                    return (
                                                                        <div key={uuid}>{language.language} - {proficiency}{' '}
                                                                            <Link onClick={(e) => handleRemoveAdditionalLanguage(e, language)}>
                                                                                [Remove]
                                                                            </Link>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </>   
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formProfessionalOrganizations">
                                                        <Form.Label>Professional Organizations of Which You Are a Member</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Professional Organizations"
                                                        value={professionalOrganizations}
                                                        name="professionalOrganizations" 
                                                        onChange={(e) => setProfessionalOrganizations(e.target.value)} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formPassword1">
                                                        <Form.Label>Password *</Form.Label>
                                                        <div>
                                                            Requirements
                                                            <ul>
                                                                <li>At least one uppercase character</li>
                                                                <li>At least one lowercase character</li>
                                                                <li>At least one digit</li>
                                                                <li>At least one non-alphanumeric (special) character</li>
                                                                <li>At least 12 characters</li>
                                                            </ul>
                                                        </div>
                                                        <Form.Control type="password" placeholder="Enter Password"
                                                        value={password1}
                                                        name="password1" 
                                                        onChange={(e) => setPassword1(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formPassword2">
                                                        <Form.Label>Re-Enter Password *</Form.Label>
                                                        <Form.Control type="password" placeholder="Re-Enter Password"
                                                        value={password2}
                                                        name="password2" 
                                                        onChange={(e) => setPassword2(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <p>
                                                        { '* Required' }
                                                    </p>
                                                    <Button variant="primary" type="submit" hidden={buttonClicked}>
                                                        Register
                                                    </Button>
                                                    <Button variant="primary" disabled hidden={!buttonClicked}>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        Loading...
                                                    </Button>
                                                </Form>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="d-flex align-items-center justify-content-center">
                                        { FooterLinks() }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>       
            </HelmetProvider>
        );
    }
    else {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Register</title>
                </Helmet>
                <div className="d-flex align-items-center justify-content-center p-3">
                <table>
                    <tbody>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center Outer">    
                                {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                            </td>
                        </tr>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center">
                                { FooterLinks() }
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </HelmetProvider>
        );
    }
}

export default Register;