import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button'
import Requirement from "./Requirement";
import RequirementSet from "./RequirementSet";

const QualificationRequirement = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState({});
    const [qualification, setQualification] = useState({});
    const [update, setUpdate] = useState(false);
    const [requirements, setRequirements] = useState([]);
    const [requirementSets, setRequirementSets] = useState([]);

    const qualificationUuid = useRef("");

    useEffect(() => {
        fetchData();
        setUpdate(false);
    }, [update]);

    const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
		    qualificationUuid.current = queryParameters.get("qualificationUuid");
            let qualificationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/" + qualificationUuid.current, 
                { withCredentials: true, params: { includeRequirements: true } });
            setQualification(qualificationResponse.data);
            let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + qualificationResponse.data.organizationUuid, 
                { withCredentials: true });
            setOrganization(organizationResponse.data);
            let requirementSetResult = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/" + qualificationUuid.current + "/requirementSets", 
                { withCredentials: true } );
            let sortedRequirementSets = requirementSetResult.data.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            let sortedRequirements = qualificationResponse.data.requirements.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            const requirementSetsArray = [];
            sortedRequirementSets.forEach((value) => {
                requirementSetsArray.push({
                    uuid: value.uuid,
                    name: value.name,
                    description: value.description,
                    requirements: sortedRequirements.filter((r) =>
                        r.requirementSet &&
                        r.requirementSet.requirementSetUuid === value.uuid
                    )
                });
            });
            let filteredRequirements = sortedRequirements.filter((r) => !r.requirementSet);
            setRequirements(filteredRequirements);
            setRequirementSets(requirementSetsArray);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let handleRemoveRequirement = async (e, qualificationRequirementUuid) => {
        e.preventDefault();
        try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/qualification/qualificationrequirement/delete/" + qualificationRequirementUuid, 
                { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
        }
    };

    let handleRemoveRequirementFromRequirementSet = async (e, requirementQualificationRequirementSetUuid) => {
        e.preventDefault();
        try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + 
                "/qualification/requirementSet/remove/" + requirementQualificationRequirementSetUuid, 
                { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Qualification Requirements</title>
            </Helmet>
            <div className="p-2">
                <h6><a href={"/administer?organizationUuid=" + organization.uuid}>{organization.name}</a>
                {' > '}<a href={"/qualification/list?organizationUuid=" + organization.uuid}>Qualifications</a>
                {' > '}<a href={"/qualification/edit?organizationUuid=" + organization.uuid + "&qualificationUuid=" + qualification.uuid}>{qualification.name}</a>
                {' > '}Requirements</h6>
            </div>
            <div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Qualification Requirements</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <div className="Details Margin-Bottom">
                        <div className="Center-Text">
                            <h5>{qualification.name}</h5>
                        </div>
                        { qualification.description &&
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {qualification.description}
                            </div>
                        }
                    </div>
                    <Requirement 
                        setUpdate={setUpdate} 
                        setAlertVariant={setAlertVariant}
                        setMessage={setMessage}
                        qualification={qualification}
                    />
                    <RequirementSet
                        setUpdate={setUpdate} 
                        setAlertVariant={setAlertVariant}
                        setMessage={setMessage}
                        qualification={qualification}
                        update={update}
                    />
                    <h5 className="Margin-Bottom">Requirements</h5>
                    { requirementSets.map((requirementSet) => {
                        let iteration = 0;
                        return(
                            <div className="Details Margin-Bottom" key={requirementSet.uuid}>
                                <h5 className="mb-1">{requirementSet.name}</h5>
                                <div>
                                    {requirementSet.description}
                                </div>
                                { requirementSet.requirements && requirementSet.requirements.map((requirement) => {
                                    iteration = iteration + 1;
                                    return(
                                        <div key={requirement.requirementUuid}>
                                            <>
                                                { iteration > 1 &&
                                                    <div>OR</div>
                                                }
                                            </>
                                            <div className="list-group" key={requirement.requirementQualificationUuid}>
                                                <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-flex w-100 justify-content-between">
                                                        <h5 className="mb-1">{requirement.name}</h5>
                                                        <small>
                                                            {requirement.requirementType.name}
                                                        </small>
                                                    </div>
                                                    {requirement.description &&
                                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                                            <p className="mb-1">{requirement.description}</p>
                                                        </div>
                                                    }
                                                    <Button variant="danger" onClick={(e) => handleRemoveRequirement(e, requirement.requirementQualificationUuid, false)} type="submit" style={{marginRight: "10px"}}>
                                                        Remove From Qualification
                                                    </Button>
                                                    <Button variant="danger" onClick={(e) => handleRemoveRequirementFromRequirementSet(e, requirement.requirementSet.requirementQualificationRequirementSetUuid, false)} type="submit">
                                                        Remove From Set
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                    { requirements && requirements.map((requirement) => {
                            return(
                                <div className="list-group" key={requirement.requirementQualificationUuid}>
                                    <div className="list-group-item list-group-item-action flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{requirement.name}</h5>
                                            <small>
                                                {requirement.requirementType.name}
                                            </small>
                                        </div>
                                        {requirement.description &&
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                <p className="mb-1">{requirement.description}</p>
                                            </div>
                                        }
                                        <Button variant="danger" onClick={(e) => handleRemoveRequirement(e, requirement.requirementQualificationUuid, false)} type="submit">
                                            Remove From Qualification
                                        </Button>
                                    </div>
                                </div>
                            )
                    })}
                    { qualification.requirements && qualification.requirements.length < 1 &&
                        <p className="Center-Text">This qualification has no requirements.</p>
                    } 
                </div> 
            </div> 
        </HelmetProvider>
	);
}

export default QualificationRequirement;