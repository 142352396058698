import axios from 'axios';
import { FaSearch } from "react-icons/fa";

import "./Holders.css";
import HoldersList from "./HoldersList";

const HolderSearchBar = ({qualificationVersionUuid, setHolder, setRequirements, setValid, holderSearchResults, setHolderSearchResults, holderInput, setHolderInput, setRequirementSets, setAdminComments, isAdmin, isSuperUser}) => {

    const searchHolderData = async (value) => {
        try
        {
            if (!qualificationVersionUuid)
            {
                setHolderInput("");
                return;
            }
            if (!value)
            {
                return;
            }
            let results = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/holders/" + qualificationVersionUuid, 
                { 
                    params: {
                        searchParameter: value
                    },
                    withCredentials: true 
                });
            setHolderSearchResults(results.data);
        }
        catch (error)
        {
            console.log(error);
        }
    };

    const handleHolderChange = (value) => {
        setHolderInput(value);
        if (value === "")
        {
            setHolderSearchResults([]);
        }
        else
        {
            searchHolderData(value);
        }
    }

    return (
        <>
            <div className="input-wrapper">
                <FaSearch id="search-icon" />
                <input placeholder="Type to search users..."
                    className="input"
                    value={holderInput} 
                    onChange={(e) => handleHolderChange(e.target.value)} 
                />
            </div>
            <div className="Margin-Bottom">
                <HoldersList 
                    holderSearchResults={holderSearchResults} 
                    setHolderInput={setHolderInput} 
                    setHolderSearchResults={setHolderSearchResults}
                    setHolder={setHolder}
                    setRequirements={setRequirements}
                    qualificationVersionUuid={qualificationVersionUuid}
                    setValid={setValid}
                    setRequirementSets={setRequirementSets}
                    setAdminComments={setAdminComments}
                    isAdmin={isAdmin}
                    isSuperUser={isSuperUser}
                />
            </div>
        </>
    );
};

export default HolderSearchBar;
